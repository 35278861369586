#teaching_title{
  &::before{
    content: '';
	display: block;
	width: 100%;
	height: 9em;
	margin-bottom: 2em;
	background-image: url($teaching_bg_url);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
#teaching_step1, #teaching_step2, #teaching_step3{
  ul{
     @include display-flex(center, center, row);
	 justify-content: space-around;
	 li{
	   text-align: center;
	   &::before{
	     content: none;
	   }	   
       a{
	     color: #999999;
		 color: transparent;
	     &::before{
	       content: '';
	       display: block;
	       width: 8em;
	       height: 8em;
           background-position: center center;
           background-repeat: no-repeat;
	       background-size: 100% 100%;
		   @media (min-width: $screen_sm) {
	         width: 11em;
	         height: 11em;
	       }
	     }
	   }       
	 }
  }
}
#teaching_step1, #teaching_step2, #teaching_step3{
  margin-bottom: 0 !important;
}
#teaching_step1{
  li{
    &:first-child{
	  a::before{
	    background-image: url($logo_binance_url);
	  }
	}
	&:last-child{
	  a::before{
	    background-image: url($logo_max_url);
		width: 7.4em;
	    height: 7.4em;
		@media (min-width: $screen_sm) {
	      width: 10.4em;
	      height: 10.4em;
	    }
	  }
	}
  }
}
#teaching_step2{
  li:first-child{
    a::before{
	  background-image: url($logo_metamask_url);
	}
  }
}
#teaching_step3{
  margin-bottom: 4em !important;
  li:first-child{
    a::before{
	  background-image: url($logo_metatalk_url);
	  width: 5.8em;
	  height: 5.8em;
	  @media (min-width: $screen_sm) {
	      width: 7em;
	      height: 7em;
	    }
	}
  }
}