//QUOTE
blockquote{
  position: relative;
  margin:0 auto;
  padding: 2.4em;
}
blockquote.quote{
  color: $quote_color;
}
blockquote.quote:before,
blockquote.quote:after{
    position: absolute;
    font-size: 2em;
	
}
blockquote.EN:before{
    content: "\21AC";
	//content: "\00F0";	
	//content: "\00D0";
	//content: "\27A6";
	//content: "§";
		
    margin-top: -1em;
    margin-right: -1em;
    //right: 100%;
	left: 0;
	width: 0.6em;
	content: url($quote_before_url);

	
	
}
blockquote.EN:after{
    //content: "\21AB";
	//content: "\20BF";
	//content: "\27A5";
	
    //margin-left: -1em;
    //left: 100%;
	right: 0;
	width: 0.6em;
	content: url($quote_before_url);
	-webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);
}


//form
form > div:not(:last-child){
  //margin-top: 1em;
}


//按鈕
.btn_fill{
  display: inline-block;
  padding: $btn_padding;
  color: $btn_fill_font_color;
  background: $btn_fill_bg_color;
  border-radius: $btn_radius;
  text-shadow: none;
  box-shadow: $box_shadow;
  font-size: $btn_font_size;
  letter-spacing: $btn_letter_spacing;
}

//按钮2

.btn_fill2{  
  color: $btn_fill2_font_color;
  background: $btn_fill2_bg_color;
  
}

label, input[type=text], input[type=password]{
  font-size: 1em; //1.2em;
}


//段落小標
.sub_title{
  line-height: 4em;
  font-style: italic;
  //color: $section_subtitle_font_color;
}


//下拉選單
select{
  font-size: 1em;
  padding: 0.25em;
}
option{
  &:hover {
    background-color: $select_option_bg_color;
  }
  &:checked{
    background: $select_option_bg_color;
  }
}

//滑動
.slide{
  //@include display-flex(center, center, row);
  //justify-content: space-between;
  //@include rwd-flex();
  @include display-flex(center,flex-start, column);
  align-items: flex-start;
  @media (min-width: $screen_sm) {
    @include display-flex(flex-start,center,row);
    justify-content: space-between; 
  }
  &.promise{
    @include display-flex(flex-start,center,row);
    justify-content: space-between; 
	//justify-content: space-around; 
	.item:not(:last-child){
	  //margin-right: 1.2em;
	}
	.icon_wrap{
	  position: relative;
	  display: flex;
      width: 72px;
      height: 72px;
      //background: #dab169;
	  border: 0.15em solid #dab169;
	  //background: linear-gradient(-45deg, rgba(106,85,22,0.5) 0%, rgba(0,0,0,1) 61%, rgba(60,48,12,0.5) 100%);
      margin: 0 auto;
      border-radius: 100%;
      justify-content: center;
      align-items: center;
	  margin-bottom: 1em;
	}
    .icon_img{
	  height: 2em;
	  @media (min-width: $screen_sm) {
        height: 2.5em;  
      }
	}
	h3{
	  white-space: break-spaces !important;
	  font-size: 1.05em;
      letter-spacing: 0.14em;
	}
  }
  .item{
    width:100%;
	text-align: center;
	margin-bottom: 2em;
	@media (min-width: $screen_sm) {
	  width:30%;
	}
	.head_img{
	  width: 70%;
	  @media (min-width: $screen_sm) {
	    width: 80%;
	  }
	}
    .art_img{
	  cursor: pointer;
	  width: 80% !important;
	  @media (min-width: $screen_sm) {
	    //width:80%;
	  }
	}
	h3{
	  color: $slide_title_color;
	  a{
        color: $slide_h3_a_color;
	    &:visited{
	      color: $slide_h3_a_color;
	    }
	  }
	  &:last-child{
	    a{
	      color: $slide_a_visited_color;
		  &:visited{
		    color: $slide_a_visited_color;
		  }
	    }
	  }
	}
	a{
      color: $slide_a_color;
	  &~h3{
	  
	  }
	  &:visited{
	    color: $slide_a_visited_color;
	  }
	}
	.purchasing{ //收藏
	    cursor: pointer;
	    color: $purchasing_color;
		text-decoration: underline;
        &::before{
		  display: inline-block;
		  margin-right: 0.2em;
          content: '';
		  vertical-align: middle;
	      //background-image: url($purchasing_bg_url);
		  width: 1.05em;
		  height: 1.05em;
		  background-size: 100% 100%;
          background-position: center center;
	      background-repeat: no-repeat;
        }
    }
	h3, a{
	  @include ellipsis-oneline;
	}
  }
  &.ip_slide .item > a:last-child{
	  //text-decoration: none;
	}
}

  //chrome
  /*
  table::-webkit-scrollbar{
	width: $scrollbar_width;
	height: $scrollbar_height;
	
  }
  table::-webkit-scrollbar-track{
    background: $scrollbar_track_color;
	
  }
  table::-webkit-scrollbar-thumb{
    background: $scrollbar_thumb_color;
	//border-radius: 0.2em; //0.1em;
  }
  
  table::-webkit-scrollbar-button {
      background: $scrollbar_thumb_color;
	  width: 1em;
    }
  */



//表格

.assets_table{
  position: relative;
  table{
    position: relative;
    border-collapse: collapse;
    border-spacing: 0;
  
  display: block;
  overflow-x: scroll; //auto;
  white-space: nowrap;
  
  //scroll-snap-type: x proximity;
  
  
  //padding-left: 8em;
  //border-top: $table_thead_border;
  
  .headcol, .headcol2{
    //position: absolute;
    //left: 0;
    //top: auto;
	background: #fff;
	//padding-top: 0.4em;
	
	position: sticky;    
    //z-index: 2;
  }
  .headcol{
    left: 0;
    //width: 1em; //3em;
	min-width: 3em;
  }
  .headcol2{
    //width: 8em;
	//left: 3em;
	left: 1.84em;
	left: 2.48em;
	left: 3em;
	background: #fafafa;
	
  }
  thead{
    /*
	tr, .headcol, .headcol2{
	  height: $table_thead_height;
	  border-top: $table_thead_border;
	  border-bottom: $table_thead_border;
	}
	tr, .headcol, .headcol2{
	  border-top-color: transparent;
	  margin-bottom: 1px;
	}
	*/
	
	  position: sticky;
	  top: 0;
	  left: 0;
	  //z-index: 1;
	  background: $table_bg_color;
  }
  
  tbody{
	tr, .headcol, .headcol2{
	  //height: $table_tbody_tr_height;	  
	  //border-bottom: $table_thead_border;
	}
	.headcol{
	  //line-height: ($table_tbody_tr_height - 1em);
	}
  }
  tr, .headcol,.headcol2{
    //line-height: $table_tbody_tr_height;
	height: $table_tbody_tr_height;
    border-top: $table_thead_border;
    border-bottom: $table_thead_border;  
  }
  th, td{
    text-align: center;
	padding: 0 0.35em; //0.05em;
	padding: 0 0.38em;
  }
  
  img{
    //width: 100%;
	vertical-align: middle;
	width: 60px;
	height: 60px;
	object-fit:cover;
  }
  th{
    width: 10%;	
  }
  .artwork a, .author{
    @include ellipsis-oneline;
	max-width: 6em;
  }
  .no{
    //width: 3em;
  }
  .item_img{
    cursor: pointer;
    width: 5em; //8%;
  }
  .agent{
    width: 3em; //12%;
  }
  .no_result{
    height: 8em;
  }
  .unpublished a{
	text-decoration: none;
  }
 }

  
}

//表格分頁
.table_pagination{
  .selected {
      color: $table_pagination_active_color;
      background-color: $table_pagination_active_bg_color;
    }
    .disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  text-align: center;
  .pagination {
    display: inline-block;
	a {
	  cursor: pointer;
      color: $table_pagination_font_color;
      float: left;
      padding: 0.4em 0.9em;
      text-decoration: none;
	  border-radius: 0.375em;
	  border: $table_pagination_border;
	  margin: 0 0.2em 0.4em;
	  &.active, &:hover{
	    color: $table_pagination_active_color;
		background-color: $table_pagination_active_bg_color;
	  }
	  &.prev, &.next{
        color: $table_pagination_arror_color;
        border: $table_pagination_arror_border;
		&:hover{
		  background: $table_pagination_arror_hover_color;
		}
      }
	}
	
  }
}

//搜尋框
.search{
  @include display-flex(center,flex-start, column);
  align-items: flex-start;
  
//  @media (min-width: $screen_xs) {
  @media (min-width: $screen_sm) {
    @include display-flex(center,flex-start,row);
  }
  margin: 1.5em auto;
  
  .box{
    @include display-flex(center,center, row);
	background: $search_bg_color;
    padding: 0.3em;
    border: $search_border;
	border-radius: 6px;
	justify-content: center;
	margin-bottom: 0.5em;
	//  @media (min-width: $screen_xs) {
    @media (min-width: $screen_sm) {
      margin-bottom: 0;
    }
	.field{
	  font-size: 1em;
	  padding: 0 0.4em;
	  overflow: hidden;
      text-overflow: ellipsis;
	  display: -webkit-box; 
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1 !important;
	}
	.magnifier{
	  position: relative;
	  top: -0.07em;
	  font-size: 2em;
	}
	.close{
	  cursor: pointer;
	  font-size: 1em;
      border-radius: 50%;
	  //color: $search_close_font_color;
	}
	.text{
	  font-size: 0.5em;
	}
  }
  & > div:not(:last-child){
    margin-right: 1em;
  }
}

button{
    &:not(:last-child){
	  margin-right: 1em;	  
	  @media (min-width: $screen_xs) {
	    //margin-right: 1.618em;
	  }
	}
    //padding: 0.4em 0.4em;
    //@media (min-width: $screen_xs) {
	   padding: 0.4em 0.5em;
	//}
	font-size: 1em;
	border-radius: 0.5em;
	color: $search_btn_color;
	background: $search_btn_bg_color;
	cursor: pointer;
  }

.btn_more{
  text-decoration: underline;
  //&::before{
  &::after{
    margin-left: 0.25em;
    content: '\00226B'; //'\27A4'; //'\2BAB'; //'\21E2'; //'\2B8D'; //'\21AC'; //'\27A5'; //'\21BA';
	display: inline-block;
	
  }
}

//NAV-MENU
#menuToggle{
  
  //display: block;
  position: relative;
  z-index: 1;
  input
  {
    display:none;
  }
  
  label{
    cursor: pointer;
	span{
	  display: block;
      width: 2em;
      height: 0.2em;
      &:not(:last-child){
	    margin-bottom: 0.3em;
	  }
      position: relative;
    
      background: $menu_bar_color;
      //border-radius: 0.18em;
      z-index: 1;
      transform-origin: 0.375em 0;
    
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
      @media (min-width: $menu_tooggle_screeen_size) {
	    display: none;
      }
	  &:first-child
      {
        transform-origin: 0% 0%;
      }
      
      &:nth-last-child(2)
      {
        transform-origin: 0% 100%;
      }
    }  
  }
  
  
  #menu_chkbox:checked 
  {
    & ~ label{
	  position: relative;
	  & > span{
	    opacity: 1;
        //transform: rotate(45deg) translate(-0.2em, -0.1em);
	    //transform: rotate(45deg) translate(-1em, -0.5em);
	    transform: rotate(45deg) translate(-0.5em, -0.5em);
        background: $menu_close_color;
        &:nth-last-child(2)
        {
          //transform: rotate(-45deg) translate(0, 0);
	      //transform: rotate(-45deg) translate(-0.5em, 0.15em);
	      transform: rotate(-45deg) translate(-0.5em, 0.65em);
        }
	    &:nth-last-child(3)
        {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }
	  }
	  &::after{
	    position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
	    content: '';
	    width: 100%;
	    height: 100%;
	    background: transparent;
		cursor: auto;
	  }
	} //label
	&  ~ .menu{
      margin-top: 1em;
      display: block;
	  //position: absolute;
	  position: fixed;
	  width: 18em;
      padding: 3.125em;
      padding-top: 5em; //7.8em;
      right: -0.5em;
      background: $menu_bg_color;
	  li{
	    display: block;
	    margin-bottom: 0.5em;
	  }	   
    }//.menu
  }
  
  .menu{
    font-size: 0.92em;
    display: none;
    //@media (min-width: $screen_sm) {
	@media (min-width: $menu_tooggle_screeen_size) {
	  display: block;
    }
	.lang_list{
	  font-size: 0.9em;
	  margin-bottom: 1em;
      .icon_img{		
	     margin-top: -0.18em;
	  }
	}
  } 
  
}


//賦能列表
.flex-table{
  margin-top: 0.5em;
  @include display-flex(center,flex-start, column);
  align-items: flex-start;
  @media (min-width: $screen_sm) {
    @include display-flex(flex-start,center,row);
	@include flex-wrap(wrap);
    justify-content: space-between; 
  }
  .item{
    width:100%;
	margin-bottom: 2em;
	
	@media (min-width: $screen_sm) {
	  width:45%;
	}
    .pro_img{
	  width: 100%;	
      height: 15em;	  
	  object-fit:cover;
	  
	  //clip-path: polygon(0 0, 63% 0, 39% 100%, 0% 100%);
	  //clip-path: polygon(25% 0, 100% 0, 100% 100%, 44% 100%);
	  
	  @media (min-width: $screen_sm) {
	    
	  }
	}
	.pro_list{
 	  
	}
	h3{
	    //font-size: 1.2em;
	    color: $slide_title_color;
	  }
	  a{
        color: $slide_a_color;
	    &:visited{
	      color: $slide_a_visited_color;
	    }
	  }
  }
}

.flex-table-enpower{
  .pro_img{
    width: 60%;
  }
}

.flex-table-cooperation{
  //flex-wrap: nowrap;
  .item{
    width: 100%;
	@include display-flex(stretch,center, column);
	@media (min-width: $menu_tooggle_screeen_size) {
	  width: 30%;
	}
	h3{
	  a, a:visited{
	    color: #dab169;
	  }
	}
	.pro_img_wrap{
	  position: relative;
	  @include display-flex(center,center, row);
      height: 10em;
	  .pro_img{
        height: 100% !important;
	    object-fit: contain;
      }
	  &::after{
	    position: absolute;
	    //z-index: -1;
		content: '';
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.5;
		filter: saturate(0.1);
	  }
	  //background: #333;
	  background-color: rgba(218,177,105,0.1);
      .pro_img{
	    z-index: 1;
	  }
	}
	//&.logo_shangsun{
	&:nth-child(1){
	  .pro_img_wrap{
	    &::after{
	      background-image: url($logo_shangsun_bg_url);
	    }
	  }
      .pro_img{
        height: 90% !important;
		@media (min-width: $menu_tooggle_screeen_size) {
		  height: 80% !important;
		}
      }
    }
	//&.logo_netbridge{
	&:nth-child(2){
	  .pro_img_wrap{
	    &::after{
	      background-image: url($logo_netbridge_bg_url);
	    }
	  }
	}
	//&.logo_bingx{
	&:nth-child(3){
	  .pro_img_wrap{
	    &::after{
	      background-image: url($logo_bingx_bg_url);
	    }
	  }
	  .pro_img{
	    width: 80%; //84%; //72%;
	    @media (min-width: $menu_tooggle_screeen_size) {	
          width: 98%; //84%;
		}
      }
	}
  }
}

//顧問列表, 藝術資產憑證五大優勢
.flex-table-one{
  .item{
    position: relative;
    width: 100%;
  }
  .pro_img{
    background-color: #e1ddd1;
	@media (min-width: $screen_sm) {
	  background-color: none;
	}
  }
  /*
  .pro_video_wrap{
    position: relative;
    width: 100%;
    video{
      width: auto;
  	  height: 300px;
  	  @media (min-width: $menu_tooggle_screeen_size) {
        width: 100%;
  	    height: unset;
  	  }
    }
  }
  */
  /*
  .pro_video_wrap{
    position: relative;
	padding-top: 56.25%;
	width: 100%;
	height: 0px;
    video, iframe{
      position: absolute;
	  width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  */
}

.pro_video_wrap{
    position: relative;
	padding-top: 56.25%;
	width: 100%;
	height: 0px;
    video, iframe{
      position: absolute;
	  width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

#team ~ .flex-table-one{
  .pro_list{
    position: relative;
	@media (min-width: $screen_sm) {
	  position: absolute;
      top: 2em; //5em;      
	  right: 2em;
	}
  }
}


/* service 服務項目 */
.flex-table-service_p{
  flex-direction: row;
  justify-content: center;
  //background-image: url($service_bg_02_url);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  .item{
    width: 30%;
	margin-bottom: 0;
	@include display-flex(center,center,column);
	&:nth-child(1), &:nth-child(3){
	  margin-top: 3.8em;
	}
	&:nth-child(2), &:nth-child(5){
	  width: 25%;
      margin-top: -2.4em; //-4em;
	  margin-right: -4em;
	}
	&:nth-child(4), &:nth-child(6){
	  margin-top: 2em;
	}
	&:nth-child(5){
	  margin-top: 10em;	}
	&:nth-child(1), &:nth-child(4){
	 margin-right: 0;	  
	}
	&:nth-child(3), &:nth-child(6){
	  margin-left: 4em;
	}
	
  }
  .pro_img_wrap{
    width: 160px;
	@media (min-width: $screen_xs) {
	  width: 180px;
	}
	@media (min-width: $screen_sm) {
	  width: 210px;  
	}
	.pro_img{
	  object-fit: contain; 
	}
  }
  
  .pro_list{
    position: absolute;
    @include display-flex(center,center,column);
    width: 160px;
	padding: 1em;
	@media (min-width: $screen_sm) {
      width: 140px;
	  padding: 0.5em;
    }
	text-align: center;
	h3{
	  font-size: 1.8em;
	  margin: 0 0 0.2em 0;
	}
	h4{
	  margin:0;
	  line-height: 1em;
	}
  }
  
  //第二列
  & ~ .flex-table-service_p{
    background-image: url($service_bg_04_url);
    background-position: center center;
	background-size: contain;
    background-repeat: no-repeat;
	
	.item{	  
      &:nth-child(1), &:nth-child(3){
	    margin-top: -12em;
		
		@media (min-width: $screen_xs) {
		  margin-top: -10.5em;
		}		
		@media (min-width: $screen_sm) {
		  margin-top: -7em;
		}
		@media (min-width: $screen_md) {
		  margin-top: -6.2em;
		}
	  }
	  &:nth-child(2){
		margin-top: -6em;
		@media (min-width: $screen_xs) {
		  margin-top: -5em; 
		}		
		@media (min-width: $screen_sm) {
		  margin-top: 0.6em;
		}
	  }	  
      .pro_list{
	    color: #2b2316;
	    h3{
	      color: #2b2316;
	    }
	  }
    }
  }  
}

/* service 4步驟*/
.flex-table-service_f{
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  background-image: url($service_bg_03_url);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  
  
  @media (min-width: $screen_xs) {
    flex-direction: row;
	flex-wrap: wrap;
  }
  .item{
    line-height: 1.1em;
    width: 80%;
	@media (min-width: $screen_xs) {
	  width: 40%;
	}
    margin-bottom: 0;
	@include display-flex(center,center,row);
	&:nth-child(1), &:nth-child(2){
	  h4{
	    color: #dab169;
	  }
	}
	&:nth-child(3), &:nth-child(4){
	  h4{
	    color: #2b2316;
      }
	}
	&:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4){ 
	  .pro_img_wrap{
	    @include display-flex(center,center,row);
	  
	    &::before, &::after{
		  margin-right: -1em;
	      content: '';
	      display: inline-block;
	      //margin-right: -1.5em;
		  width: 60px;
          height: 130px;
	      background-image: url($service_item_01_b_bg_url);
	      background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
		  @media (min-width: $screen_sm) {
		    width: 70px;
            height: 144px;  
		  }
	    }
	    &::after{
	      background-image: url($service_item_01_a_bg_url);
          margin-right: 0;
          margin-left: -1em;		  
		  @media (min-width: $screen_xs) {
		    margin-right: -1em;
            margin-left: 0;
		  }
	    }
	  }
	}
	&:nth-child(3){ 
	  .pro_img_wrap{
	    &::before{
		  background-image: url($service_item_03_b_bg_url);
		}
		&::after{
	      background-image: url($service_item_03_a_bg_url);
	    }
	  }
	}
	&:nth-child(2){ 
	  .pro_img_wrap{
		&::after{
		  background-image: url($service_item_02_a_bg_url);
	    }
	  }
	}
	&:nth-child(4){ 
	  .pro_img_wrap{
		&::before{
		  background-image: url($service_item_03_b_bg_url);
		  @media (min-width: $screen_xs) {
		    //background-image: none;
		  }
		}
		&::after{
	      background-image: url($service_item_04_a_bg_url);
	    }
	  }
	}
	&:nth-child(2), &:nth-child(4){ 
	  .pro_img_wrap{
	    &::after{
		  margin-right: 0;
		  margin-left: -1em;
		  @media (min-width: $screen_xs) {
		    margin-left: -1em;
		  }
		}
	  }
	}
  }
  .pro_img_wrap{
    
	.pro_img{
      width: 240px; //250px;
	
	
	  @media (min-width: $screen_xs) {
	    width: 230px;
	  }
	  @media (min-width: $screen_sm) {
	    width: 260px;  
	  }	
	
	  object-fit: contain; 
	}
  }
  .pro_list{
    position: absolute;
    @include display-flex(center,center,column);
    width: 174px;
	padding: 1em;
	@media (min-width: $screen_sm) {
      width: 140px;
	  padding: 0.5em;
    }
	text-align: center;
	h3{
	  font-size: 1.8em;
	}
  }
}


/* service 3優勢 */
.flex-table-service_t{
  
  background-image: url($service_bg_02_url);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  
  .item{
    width: 100%;
	text-align: center;
	@media (min-width: $screen_sm) {
	  width: 30%;
	}
	a{
	  color: #dab169 !important;
	}
	.pro_list{
	  display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
	  gap: 1em;
	}
	h3{
	  color: #000;
      background: #dab169;
      width: fit-content;
      border-radius: 3em;
	  padding: 0.4em 0.6em;
	}
	h3, h4{
	  max-width: unset;
	  @media (min-width: $screen_sm) {
	    max-width: 12.5em; //200px;
	  }
	}
  }
  .item:nth-child(1){
    width: 100%;
  }
  .item:nth-child(2),.item:nth-child(3){
    width: 100%;
	@media (min-width: $screen_sm) {
	  width: 25%;
	}
  }
  .item:nth-child(3){
    order:3;
    @media (min-width: $screen_sm) {
      order:4;
    }
  }
  .item:nth-child(4){
    width: 100%;
	@media (min-width: $screen_sm) {
      width: 40%;
    }
    .pro_img{
	  width: 80%;
	  height: auto;
	  @media (min-width: $screen_xs) {
        width: 70%;
      } 
	  @media (min-width: $screen_sm) {
        width: 100%;
      } 
	}
    order:4;
    @media (min-width: $screen_sm) {
      order:3;
    }
  }
}



/* service 3方案 */
.flex-table-service_c{
	-webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  align-items: center;
  background-image: url($service_bg_06_url);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  @media (min-width: $screen_sm) {
    align-items: flex-start;
  }
  .item{
    border: 2px solid #dab169;
    border-radius: 1em;
    padding: 3em 1em;
    background: rgba(35,25,22,0.5);
    width: 80%;
	@media (min-width: $screen_sm) {
	  width: 30%;
	}
	.pro_list{
	  display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
	  gap: 1em;
	  p{
	    color: #dab169;
		//border-bottom: 1px solid #dab169;
	  }
	  
	}
	
	h2{
	  font-size: 1.4em;
      color: #dab169;
	  font-style: italic;
	  span:nth-child(1){
	    font-size:1.8em;
	    padding: 0.1em;
	    &::after{
		  padding-left: 0.1em;
	      content: '/';
	    }
	  }
    }
	.currency{
	  //font-size: 1.2em;
	}
	.currency, .money{
	  font-size: 1.6em;
	}
	.unit{
	  font-size: 0.8em;
	  margin-left: 0.1em;
	  display: block;
	  text-align:right;
	}
	h4{
	  color: #dab169;
	  padding: 1em;
	  border: 1px solid #dab169;
	  border-radius: 1em;
	  
	  span:first-child{
		font-size: 1.4em;
		letter-spacing: 0.08em;
	  }
	  span:nth-child(2){
	    //display: block;
	  }
	}
	&.c{
		h4{
			color: #ffffff;
			background: #dab169;
			background: linear-gradient(90deg, rgba(218,177,105,1) 0%, rgba(218,177,105,0.75) 55%, rgba(218,177,105,1) 100%);
		}
	}
	.marketing{
		text-decoration: underline;
		text-underline-offset: 0.4em;
		text-decoration-color: #dab169;
		& ~ ul{
			text-align: justify;
			//padding: 0.4em 1em 1em 1em;
			padding: 0.4em 1em 1em 2.2em;
			li::before{
				content: '\002022';
				margin-left: -0.8em;
			}
		}
	}
  }
}

.flex-table-service_c, #service_s{
  .btn_more{
    color: #000;
    background: #dab169;
    border-radius: 2em;
    padding: 0.4em 0.8em;
  }
}

#service_s{
  font-size: 1.1em;
  text-align: center;
  p{
    color: #dab169;
	margin-top: 1em;
  }

}



/* service 更多展間連結 */
#service_v{
  text-align: center;
}


/* service Q&A  */
#service_q{
  ol{
    span:nth-child(2){
	  display: block;
	  margin-left: 1.4em;
	}
	li:not(:last-child){
	  margin-bottom: 1em;
	}
  }
}


/* service 放影片區塊 */
#service_ac{
  background-image: url(../img/service_banner.png);
  background-size: cover;
  .flex-table.service_ac{
    
    .item{
	  width: 100%;
	  display: flex;
	  flex-direction: column-reverse;
	  @media (min-width: $screen_pad) {
	    //flex-direction: row;
	  }
	  gap: 2em;
	  .pro_list{
	    width: 100%;
		height: 80%;
		display: flex;
        flex-direction: row;
		justify-content:space-between;
        gap: 2em;
		@media (min-width: $screen_pad) {
		  //width: 50%;
		  //flex-direction: column;
		}
		h3{
		  position: relative;
		  font-style: italic;
          font-size: 1em;
		  @media (min-width: $screen_pad) {
            font-size: 1.2 em;
		  }		  
		  width: fit-content;
		  button{
		    font-size: 0.8em;
			background: #dab169;
		  }
		  
        }
		.arrow {
          position: absolute;
          bottom: 0;
		  //right: -1em;
          left: 0;
		  transform: translate(-50%, -50%);
          transform: rotate(-90deg);
          
        }
        
        .arrow span {
          display: block;
          width: 1em;
          height: 1em;
          border-bottom: 5px solid white;
          border-right: 5px solid white;
          transform: rotate(45deg);
          margin: -8px;
          //animation: animate 2s infinite;
        }
        
        .arrow span:nth-child(2) {
          animation-delay: -0.2s;
        }
        
        .arrow span:nth-child(3) {
          animation-delay: -0.4s;
        }
        
        @keyframes animate {
          0% {
              opacity: 0;
              transform: rotate(45deg) translate(20px, 20px);
          }
          50% {
              opacity: 1;
          }
          100% {
              opacity: 0;
              transform: rotate(45deg) translate(-20px, -20px);
          }
        }
		#tabRadio01, #tabRadio02{
		  display: none;
		  ~ label{
		    cursor: pointer;
		    display: inline;
			span{
		      margin-left: 1.4em;
			  border-bottom: 2px solid #dab169;
              padding: 0.5em;
		    }
			.arrow span{
			   opacity: 0.5;
			   animation: 0;
			   border-color: #dab169;
			}
		  }
		  
		  &:checked{
		    ~ label{
			  .arrow span{
			    opacity: 1;
				animation: animate 2s infinite;
			  }
		    }
          }
		}
		
	  }
	}
    video{
	  width: 100%;
	}
  }
}



//back to top
.scrolltop-wrap {
  $size: 3em;
  $offsetBottom: 4.5em;
  $offsetHorizontal: 2em;
  $scrollToRevealDistance: 12em; // scroll offset to reveal scroll-to-top link
  $color: $scrolltop_color;

  box-sizing: border-box;
  z-index: $scrolltop_zindex;
  position: absolute;
  top: $scrollToRevealDistance;
  right: $offsetHorizontal;
  bottom: 0;
  pointer-events: none;
  backface-visibility: hidden;
  @include setScrolltopColors($scrolltop_bg_color, $color, $color, $scrolltop_bg_color);
  // prevent extra page height if content is smaller than viewport
  // Firefox only
  @supports (-moz-appearance: meterbar) {
    clip: rect(0, $size, auto, 0);
  }
  a {
    $offset: - ($size + $offsetBottom); // pull up + add a small bottom space
    position: fixed; // fallback
	position: -webkit-sticky; /* Safari */
    position: sticky;
    top: $offset;
    width: $size;
    height: $size;
    margin-bottom: $offset;
    transform: translateY(100vh); // push to bottom from top (when stuck)
    backface-visibility: hidden;
    display: inline-block;
    text-decoration: none;
    user-select: none;
    pointer-events: all;
    outline: none;
    overflow: hidden;
    svg {
      display: block;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      path {
        transition: all 0.1s;
      }
    }
    #scrolltop-arrow {
      transform: scale(0.66);
      transform-origin: center;
    }
  }
  @media print {
    display: none !important;
  }
}

//shining light

@keyframes progress1 {
  0% {
    transform: scalex(0);
    opacity: .5;
  }
  90% {
    transform: scalex(1);
    opacity: 1;
  }
  92% {
    transform: scalex(1);
    opacity: 1;
  }
  100% {
    transform: scalex(1);
    opacity: 0;
  }
}

@keyframes progress2 {
  0% {
    transform: scale(.3,.8) translatez(0);
    opacity: 0;
  }
  90% {
    transform: scale(1,1) translatex(300px) translatez(0);
    //opacity: 1;
	opacity: 0.85;
  }
  100% {
    transform: scale(1,1) translatex(300px) translatez(0);
    opacity: 0;
  }
}

.line {
  /*
  position: absolute;
  top: 50%;
  width: 300px;
  left: 50%;
  margin-left: -150px;
  */
  position: relative;
  //height: 0.15em;
  //background: #dab169; //rgba(255,255,255,.1);
  //width: 100%;
  &:before {
    position: absolute;
	bottom: 0;
	left: 5%;
    animation: progress1 4s infinite;
    transform-origin: 0 0;
    content: "";
    display: block;
    //width: 300px;
    width: 88%;
	//height: 100%;
	height: 0.2em;
	background: linear-gradient(to right, rgba(255,255,255,0.1) 10%, rgba(255,255,255,0.4) 80%, rgba(255,255,255,1));
    //background: linear-gradient(to right, rgba(250,197,104,0.1) 10%, rgba(250,197,104,0.4) 80%, rgba(250,197,104,1));
	border-top-right-radius: 1.5em;
  }
  
  &:after {
    content: "";
    position: absolute;
    animation: progress2 4s infinite;
    transform-origin: 90% 50%;
    left: -4em;
    //top: -9px;
	bottom: 0;
    //width: 30px;
    width: 1em;
	height: 0.8em;
    border-radius: 0.2em;
    //background: rgba(254,220,161,.55);
	background: rgba(255,255,255,.55);
    filter: blur(8px);
    box-shadow: 0 0 10px 6px rgba(210,189,255,.4),
                -20px 0 15px 4px rgba(210,189,255,.3),
                -40px 0 15px 2px rgba(210,189,255,.2),
                -60px 0 10px 1px rgba(210,189,255,.1),
                -80px 0 10px 1px rgba(210,189,255,.05);
  }
}


.gradient-border {
  //--border-width: 3px;

  position: relative;
  //background: #dab169;
  &::after {
    border-radius: 100%;
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
      60deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%)
    );
    background-size: 300% 300%;
    background-position: 0 50%;
    //border-radius: calc(2 * var(--border-width));
    animation: moveGradient 4s alternate infinite;
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}


.categoryWrapper{
	//height: 310px;
	//width: 460px;
	//background: url("http://ohlookawebsite.com/bathroomtestfull.jpg") no-repeat center center;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.categoryWrapper:after{
	position: absolute;
	top:0;
	left: 0;
	right:0;
	bottom: 0;
	background: #dab169;
	border-radius: 100%;
	content: '';
	opacity: 0;
		-webkit-transition: opacity 0.9s ease 0s; 
		
		
}

.categoryWrapper:hover:after{
	opacity: 0.4;
}


@keyframes TransitioningBackground {
  0% {
    background-position: 1% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 1% 0%;
  }
}

.subscribe-button {
  font-size: 1rem;
  font-weight: 600;
  color: white;
  text-align: center;
  width: 200px;
  height: 60px;
  border-radius: 5px;
  // for shine & gradient effects to work
  position: relative;
  overflow: hidden;
  // for background color shift
  //background-image: (linear-gradient(270deg, #8e9ac2, #42579a));
  background-size: 400% 400%;
  animation: TransitioningBackground 10s ease infinite;
  // to ease the button growth on hover
  transition: 0.6s;

  // psuedo-element shine animation left side
  &::before {
    content: '';
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 60px;
    height: 100%;
    top: 0;
    filter: blur(30px);
    transform: translateX(-100px) skewX(-15deg);
  }

  // psuedo-element shine animation right side
  &::after {
    content: '';
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    width: 30px;
    height: 100%;
    top: 0;
    filter: blur(5px);
    transform: translateX(-100px) skewX(-15deg);
  }

  // grow button and change background gradient on hover
  &:hover {
    //background-image: (linear-gradient(to left, #2d8fe5, #d155b8));
    transform: scale(1.2);
    cursor: pointer;

    // psuedo-elements for right- and left-side shine animations
    &::before,
    &::after {
      transform: translateX(300px) skewX(-15deg);
      transition: 0.7s;
    }
  }
}

.icon_wrap{
  overflow: hidden;
  position: relative;
  //background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(26,26,26,1) 100%);
  background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(26,26,26,1) 100%);
  //background: radial-gradient(circle, rgba(150,110,65,0.5) 0%, rgba(0,0,0,1) 80%);
  //background: radial-gradient(circle, rgba(254,253,216,0.2) 40%, rgba(234,206,118,0.2) 80%);
  
  box-shadow: 0 0 20px 3px rgba(218,177,105,.4);
  
  
  &::before{
    position: absolute;
	content: '';
	top: -50%;
	left: -20%;
	width: 140%;
	height: 100%;
	border-radius: 100%;
	opacity: 0.15;
	background: linear-gradient(0deg, rgba(238,235,241,1) 0%, rgba(249,253,253,1) 17%, rgba(146,155,157,1) 34%, rgba(171,181,184,1) 48%, rgba(0,0,0,1) 59%, rgba(153,170,181,1) 67%, rgba(253,253,255,1) 87%, rgba(148,162,168,1) 100%);
  }
}

//overlay, modal
.overlay, .modal{
  display: block;
  position:fixed;top:0;width:100%;height:100vh;z-index:999;background:$overlay_bg_color;
  
}
.modal{
  .toggle-button{
    background: none;
  }
  h2{
    //display: none;
  }
}
.overlayClose, .modal .toggle-button{
  cursor:pointer;position:absolute;right:0;top:0;font-size:1.6em;padding:0.5em;z-index:1000;
  color:$colorSub1;
}
.overlayWrap,  .modal .modalWrap{
  &::after{
    position: absolute;
    display: block;
    content: 'Loading...';
	white-space: pre-line;
	z-index: -1;
	font-size: 1.4em;
	color: $colorMain1;
  }
  @include display-flex(center,center, column);
  position: relative;
  padding: 2em;
  width: 100%;
  height: 100%;
  .overlayContent{
    position: relative;
    text-align: center;
  }
  .imgPreview{
    //width: 90%;
	max-height: 90vh;
	//max-width: 100%;
	max-width: 96%;
    //max-height: 100%;
	@media (min-width: $menu_tooggle_screeen_size) {
	//@media (min-width: $screen_sm) {
      //width: 60%;
      max-width: 90%;
    }
  }
  .imgTitle{
    color: $colorMain1;
	margin-top: 1em;
	font-size: 1.2em;
	@media (min-width: $screen_sm) {
	  font-size: 1.4em;
	}
  }
  .overlayiFrame, .content{
    position: relative;	
    width: $overlayiFrame_width_xs;
	height: $overlayiFrame_height;
	min-width: $overlayiFrame_min_width;
    @media (min-width: $menu_tooggle_screeen_size) {
	  width: $overlayiFrame_width_sm;
	}
  }
  .content{
    background: #fff;
    color: #000;
    overflow-y: auto; //scroll;
    padding: 1em;
  }
}
//for ppb iframe

//20220701
.overlayFrameDesc{
  color: $colorMain1;
  font-size: 0.8em;
}


.coverX{ 
  .overlayContent{
    &::before{
	  z-index: 1;
	  position: absolute;
	  background-image: url($coverX_bg_url);
	  background-size: 88% auto;
      background-position: center left;
	  background-repeat: no-repeat;
      background-color: #000;
      display: block;
	  top: 0.26em;
	  right: 1.16em; //0.8em; //1.16em;
      width: 2.4em; //3.3em;
      height: 2.5em; //2.6em;	
      content: '';	
      animation: coverXfadeIn 5s;
      @media (min-width: $screen_sm) {
	    width: 2.4em; //3em;
		background-size: 98% auto;
	  }  	  
	}
  }
}

/*
.teaching_warp{
    position: fixed;
    right: 0;
    width: 2em;
    bottom: 8em;
    background: #dab169;
}
*/

.servicing_wrap{
    position: fixed;
    right: -1.2em; //0;
    width: 7em; //2em;
    bottom: 8em;
	z-index: $servicing_zindex;
	
	//動畫部份
	/*
	.arrow-container{
      width: 100px;
      height: 100px;
      margin: 0 auto;
      position: absolute;
      bottom: 25px;
      left: 0;
      right: 0;
	  
    }
    */
    .arrow-1{
      width: 7em;
      height: 7em;
      background: rgba(218,177,105,0.9);
      opacity: 0; //0.5;
      border-radius: 50%;
      //position: absolute;
    }
    
    .arrow-2{
      width: 5em;
      height: 5em;
      background: rgba(218,177,105,0.9);
      border-radius: 50%;
      position: absolute;
      top: 15%;
      left: 15%;
      z-index: 1;
	  display: flex;
      justify-content: center;
      align-items: center;
	      text-align: center;
    }
    
    .arrow-2:before{
	  /*
      width: 52px;
      height: 52px;
      content: "";
      border: 1px solid rgb(218,177,105);
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
	  */
    }
    
    
    /* Custom Animate.css */
    
    .animated.hinge {
      -webkit-animation-duration: 2s;
              animation-duration: 2s;
              //-vendor-animation-delay: 8s;
              animation-iteration-count: infinite;
    }
    
    
    @keyframes zoomIn {
      0% {
        opacity: 0;
        -webkit-transform: scale3d(.4, .4, .4);
                transform: scale3d(.4, .4, .4);
      }
    
      50% {
        opacity: 0.5;
      }
      
      100% {
        opacity: 0;
      }
    }
    
    .zoomIn {
      -webkit-animation-name: zoomIn;
              animation-name: zoomIn;
    }
	
	
	
}


.teaching{
    /*
	padding: 0.5em;
	a{
	  color: #000;
      text-decoration: none;
	  &.visited{
	    color: #000;
	  }
	}
	*/
	cursor: pointer;
	text-decoration: underline;
	@media (min-width: $menu_tooggle_screeen_size) {
	  position: fixed;
      right: 0;
	  padding: 0.5em;
      //width: 2.5em;
      bottom: 8em;
      background: $teaching_tag_bg_color;
	  //background: rgba(218,177,105,0.8);
	  color: $teaching_tag_color !important;
      text-decoration: none;
	  //writing-mode: vertical-rl;
      //text-orientation: upright;
	 // max-width: 7em;
	  max-width: 6.4em;
	  &.visited{
	    color: $teaching_tag_color !important;
	  }
	  
	  &::before{
	    content: '';
		margin: auto;
        display: block;
		//margin-bottom: 0.4em;
		width: 1.3em;
		height: 1.3em;
		background-image: url($teaching_tag_before_url);
		background-size: 100% 100%;
        background-position: center center;
	    background-repeat: no-repeat;
	  }
	  
	}
}

.servicing{
    letter-spacing: 0;
    padding: 0.2em;
	//cursor: pointer;
	border-radius: 2.5em;
	background: rgba(218,177,105,0.9);
	@media (min-width: $menu_tooggle_screeen_size) {
	  position: fixed;
      right: 0;
	  padding: 0.5em;
      bottom: 8em;
      
	  color: $teaching_tag_color !important;
      text-decoration: none;
	  max-width: 6.4em;
	  
	}
	a, a.visited{
	  color: #000 !important;
	  text-decoration: none;
	}
}

//收藏購買
.eth_price{
  &::before{
      content: '';
      display: inline-block;
	  //vertical-align: unset;
	  width: 0.9em; //1.4em;
	  height: 0.9em; //1.4em;
	  background-image: url($teaching_tag_before_url);
	  background-size: 100% 100%;
      background-position: center center;
	  background-repeat: no-repeat;
	  @media (min-width: $screen_xs) {
	    //vertical-align: middle;
	    width: 0.8em; //1.1em;
	    height: 0.8em; //1.1em;
	  
	  }
  }
}


@keyframes coverXfadeIn {
/*
  0%   { opacity:0; }
  55%  { opacity: 0.2; }
  56%  { opacity: 0.5; }
  57%  { opacity: 0.8; }
  58%  { opacity: 1; }
*/
   0%  { background: rgba(0,0,0,0);}
  56%  { background: rgba(0,0,0,0.2); }
  57%  { background: rgba(0,0,0,0.4); }
  58%  { background: rgba(0,0,0,0.6); }
  59%  { background: rgba(0,0,0,0.8); }
  60%  { background: rgba(0,0,0,1); }
}

//認證編號標籤
.certificated_no{
  background: transparent;
  //color: #000 !important;
  border-radius: 1em;
  border: 1px solid #dab169;
  width: fit-content;
  margin: auto;
  padding: 0 0.4em;
  cursor: pointer;
}



//認證書顯示
.certificating_modal{
  
  .modalWrap{
    
    background-image: url($certificating_bg_url);
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
	
    
    &::after{
      content: '' !important;
    }
  }
  
  .content{
    height: fit-content !important;
    //background: none !important;
	background: rgba(0,0,0,0.25) !important;
    padding: 0;
	
	border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(-30deg, rgba(255,236,141,1) 0%, rgba(114,60,1,1) 20%, rgba(114,60,1,1) 50%, rgba(114,60,1,1) 80%, rgba(255,236,141,1) 100%) 1;
    /*
	&::before {
      content:"";
      width:inherit;
      height: 42%;
      position: absolute;
      bottom: -42%;
      background: linear-gradient(to bottom, rgba(255,255,255,0.3),white);
      z-index: 1;
      transform: scaleY(-1);
    }
    */
	-webkit-box-reflect: below 2px linear-gradient(transparent, rgba(0, 0, 0, .35));
	
	//animation: animate 5s ease infinite;
	
	&::before{
	  position: absolute;
	  top: 0;
	  content: '';
	  left:-50%;
	  transform: skewX(-15deg); 
	  background: rgba(255,255,255,0.1);
	  
      transition:0.5s;
      pointer-events: none;
	  
	  width: 100%;
	  height: 100%
	}
	box-shadow: 1px 1px 250px rgba(0,0,0,1);
  }
  
  
}

.certificating_wrap{
  width: 100%;
  padding-top: 70%; //4:3 -> 75%
  position: relative;
  /*
  background-image: url($certificating_bg_url);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $certificating_bg_color;
  */
  
	
  .certificating_content{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
	//margin: 0.4em; //0.6em; //0.8em;
	padding: 1em;
	@include display-flex(center,flex-start, column);
	gap: 0.5em; //1em;
	height: 100%;
	//border: 3px solid linear-gradient(120deg, rgba(255,236,141,1) 0%, rgba(114,60,1,1) 50%, rgba(255,236,141,1) 100%);
    //border-image: linear-gradient(120deg, rgba(255,236,141,1) 0%, rgba(114,60,1,1) 50%, rgba(255,236,141,1) 100%) 1;
	border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(-30deg, rgba(255,236,141,1) 0%, rgba(114,60,1,1) 20%, rgba(114,60,1,1) 50%, rgba(114,60,1,1) 80%, rgba(255,236,141,1) 100%) 1;
    
	//border-image: linear-gradient(-30deg, rgba(255,226,119,1) 0%, rgba(213,155,24,1) 20%, rgba(134,67,0,1) 50%, rgba(206,145,7,1) 80%, rgba(223,208,89,1) 100%) 1;
	
	color: #dab169;
	
	//animation: rotate 4s linear infinite;
	
	&::after{
	  position: absolute;
      right: 0;
      bottom: 0;
	  content: '';
	  width: 3.4em; //4em;
	  height: 3.4em; //4em;
	  background-image: url($certificating_stamp_bg_url);
	  background-position: center center;
	  background-repeat: no-repeat;
	  background-size: cover;
	  @media (min-width: $screen_xs) {
	    width: 5em;
	    height: 5em;
	  }
	  @media (min-width: $screen_sm) {
	    width: 6em;
	    height: 6em;
	  }
	}
	
  }
  
  .certificating_header{
    height: 30%;
    text-align: center;
    .certificating_title{
	  img{
	    width: 11em; //12em;
		@media (min-width: $screen_xs) {
		  width: 14em;
		}
		@media (min-width: $screen_sm) {
	      width: 15em;
	    }
	  }
	  /*
	  position: relative;
	  display: inline-block;
	  &::before, &::after{
	    display: inline-block;
		content: '';
		width: 1em; //1.1em; //1.2em; //1em;
		height: 2.4em; //2.64em; //2.88em; //2.4em;
		background-image: url($certificating_title_bg_url);
	    background-size: 100% 100%;
        background-position: center center;
	    background-repeat: no-repeat;
	  }
	  &::after{
	    transform: rotateY(180deg);  
	  }
	  .certificating_title_wrap{
	    display: inline-block;
	  }
	  h1{
	    font-size: 1em; //1.1em; //1.2em;
		line-height: 1.2em;
      }
	  */
	}
	.certificating_no{	
	  position: relative;
	  font-size: 0.6em;
	  line-height: 1.5em; 
	  @media (min-width: $screen_xs) {
	    font-size: 0.8em;
	    line-height: 1.8em;
	  }
	  @media (min-width: $screen_sm) {
	    font-size: 0.8em;
		line-height: 2em;
	  }
	  /*
	  -webkit-animation: glow 1s ease-in-out infinite alternate;
        -moz-animation: glow 1s ease-in-out infinite alternate;
        animation: glow 1s ease-in-out infinite alternate;
	  */	
	  &::before, &::after{
        display:block;
		margin: auto;
		content: '';
		width: 88%; //80%; //96%; //100%;
		height: 0.1em;
	    //background: radial-gradient(circle, rgba(255,236,141,1) 0%, rgba(114,60,1,1) 20%, rgba(114,60,1,1) 50%, rgba(114,60,1,1) 80%, rgba(255,236,141,1) 100%);
		//background: radial-gradient(circle, rgba(114,60,1,1) 5%, rgba(255,236,141,1) 10%, rgba(114,60,1,1) 20%, rgba(114,60,1,1) 50%, rgba(114,60,1,1) 80%, rgba(255,236,141,1) 90%, rgba(114,60,1,1) 95%);
		background: radial-gradient(circle, rgba(170,130,57,1) 5%, rgba(255,236,141,1) 10%, rgba(114,60,1,1) 20%, rgba(114,60,1,1) 50%, rgba(114,60,1,1) 80%, rgba(255,236,141,1) 90%, rgba(170,130,57,1) 95%);
	    //background: radial-gradient(circle, rgba(170,130,57,1) 5%, rgba(255,236,141,1) 10%, rgba(114,60,1,1) 20%, rgba(255,236,141,1) 50%, rgba(114,60,1,1) 80%, rgba(255,236,141,1) 90%, rgba(170,130,57,1) 95%);
	    background: radial-gradient(circle, rgba(170,130,57,1) 15%, rgba(255,236,141,1) 20%, rgba(114,60,1,1) 30%, rgba(114,60,1,1) 50%, rgba(114,60,1,1) 70%, rgba(255,236,141,1) 80%, rgba(170,130,57,1) 85%);
		background: radial-gradient(circle, rgba(170,130,57,1) 25%, rgba(255,236,141,1) 30%, rgba(114,60,1,1) 40%, rgba(114,60,1,1) 50%, rgba(114,60,1,1) 60%, rgba(255,236,141,1) 70%, rgba(170,130,57,1) 75%);
	    background : radial-gradient(50% 50%, circle farthest-corner, rgba(255, 236, 141, 1) 0%, rgba(255, 199, 0, 1) 0%, rgba(255, 199, 0, 1) 0%, rgba(255, 219, 0, 1) 0%, rgba(255, 248, 0, 1) 0%, rgba(255, 115, 0, 1) 0%, rgba(250, 189, 105, 1) 0%, rgba(214, 109, 18, 1) 0%, rgba(198, 173, 52, 1) 0%, rgba(232, 222, 103, 1) 0%, rgba(232, 222, 102, 1) 0%, rgba(244, 213, 61, 1) 0%, rgba(249, 210, 45, 1) 0%, rgba(231, 189, 45, 1) 0%, rgba(255, 236, 141, 1) 0%, rgba(247, 226, 133, 1) 0.7%, rgba(216, 187, 102, 1) 3.84%, rgba(188, 153, 75, 1) 7.31%, rgba(165, 124, 52, 1) 11.09%, rgba(146, 100, 33, 1) 15.27%, rgba(132, 82, 19, 1) 20.02%, rgba(122, 69, 9, 1) 25.69%, rgba(116, 62, 3, 1) 33.18%, rgba(114, 60, 1, 1) 49.7%, rgba(117, 63, 4, 1) 58.46%, rgba(125, 74, 12, 1) 66.08%, rgba(140, 92, 26, 1) 73.28%, rgba(160, 117, 46, 1) 80.22%, rgba(186, 150, 72, 1) 86.97%, rgba(217, 189, 104, 1) 93.5%, rgba(254, 235, 140, 1) 99.89%, rgba(255, 236, 141, 1) 100%, rgba(203, 181, 105, 1) 100%, rgba(120, 93, 48, 1) 100%, rgba(116, 89, 45, 1) 100%, rgba(104, 76, 37, 1) 100%, rgba(84, 55, 24, 1) 100%, rgba(56, 26, 5, 1) 100%, rgba(155, 74, 14, 1) 100%, rgba(80, 41, 10, 1) 100%, rgba(80, 41, 10, 1) 100%, rgba(80, 41, 10, 1) 100%, rgba(80, 41, 10, 1) 100%);
	    //background: url($certificating_line_bg_url) no-repeat center center;
	    @media (min-width: $screen_sm) {
	      width: 80%;
	    }
		
	  }
	  span:not(:last-child){
	    margin-right: 0.5em;
	  }
	}
  }
  
  .certificating_footer{
    height: 10%;
    @include display-flex(center, center, row);
	img{
	  width: 6em; //6.4em;
	  @media (min-width: $screen_xs) {
	    width: 7em;
	  }
	  @media (min-width: $screen_sm) {
	    width: 8em;
	  }
	}
  }
}

//證書BODY
.certificating_body{
    width: 100%;
    height: 60%;
    @include display-flex(center, center, row);
    justify-content: space-between;
	gap: 1.5em;
	& > div{
	  width: 50%;
	  height: 80%; //90%; //100%;
	}
	.certificating_img_wrap{
	  text-align: center;
	  @include display-flex(center, center, row);
	}
	.certificating_img_info{
	  //@include display-flex(center, space-around, column);
	  display: table;
	  border-collapse: collapse;
	  font-size: 0.5em; //0.6em;
	  line-height: 1.4em;
	  @media (min-width: $screen_xs) {
	    font-size: 1em;
	    line-height: 1.4em;
	  }
	  @media (min-width: $screen_sm) {
	    font-size: 1.2em; //1.4em;
		line-height: 1.8em; //2.4em;
	  }
	  & > div{
	  width: 100%;
	  display: table-row;
	  justify-content: flex-start;
	  gap: 1em;	  
	  align-items: flex-start;
	  font-size: 0.8em; //1em;
	  border-bottom: 1px solid;
	  border-image: linear-gradient(-30deg, rgba(255,236,141,1) 0%, rgba(114,60,1,1) 20%, rgba(114,60,1,1) 50%, rgba(114,60,1,1) 80%, rgba(255,236,141,1) 100%) 0 0 100% 0 stretch;
	  border-image: radial-gradient(50% 50%, circle farthest-corner, rgba(255, 236, 141, 1) 0%, rgba(255, 199, 0, 1) 0%, rgba(255, 199, 0, 1) 0%, rgba(255, 219, 0, 1) 0%, rgba(255, 248, 0, 1) 0%, rgba(255, 115, 0, 1) 0%, rgba(250, 189, 105, 1) 0%, rgba(214, 109, 18, 1) 0%, rgba(198, 173, 52, 1) 0%, rgba(232, 222, 103, 1) 0%, rgba(232, 222, 102, 1) 0%, rgba(244, 213, 61, 1) 0%, rgba(249, 210, 45, 1) 0%, rgba(231, 189, 45, 1) 0%, rgba(255, 236, 141, 1) 0%, rgba(247, 226, 133, 1) 0.7%, rgba(216, 187, 102, 1) 3.84%, rgba(188, 153, 75, 1) 7.31%, rgba(165, 124, 52, 1) 11.09%, rgba(146, 100, 33, 1) 15.27%, rgba(132, 82, 19, 1) 20.02%, rgba(122, 69, 9, 1) 25.69%, rgba(116, 62, 3, 1) 33.18%, rgba(114, 60, 1, 1) 49.7%, rgba(117, 63, 4, 1) 58.46%, rgba(125, 74, 12, 1) 66.08%, rgba(140, 92, 26, 1) 73.28%, rgba(160, 117, 46, 1) 80.22%, rgba(186, 150, 72, 1) 86.97%, rgba(217, 189, 104, 1) 93.5%, rgba(254, 235, 140, 1) 99.89%, rgba(255, 236, 141, 1) 100%, rgba(203, 181, 105, 1) 100%, rgba(120, 93, 48, 1) 100%, rgba(116, 89, 45, 1) 100%, rgba(104, 76, 37, 1) 100%, rgba(84, 55, 24, 1) 100%, rgba(56, 26, 5, 1) 100%, rgba(155, 74, 14, 1) 100%, rgba(80, 41, 10, 1) 100%, rgba(80, 41, 10, 1) 100%, rgba(80, 41, 10, 1) 100%, rgba(80, 41, 10, 1) 100%) 0 0 100% 0 stretch;
	  & > span{
	    //display: inline-block;
		display: table-cell;
	    
		&:not(:last-child){
		  padding-right: 0.5em;
		}
		
		&:first-child{
		  //width: 35%;
		}
	  }
	 }
	}
	.certificating_img{
	  //width: 80%; //100%;
	  //height: 100%;
	  //width: auto;
	  max-width: 100%;
      max-height: 100%;
	  box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
	}
  }

//BANNER放影片
.banner_video_wrap{
  position: absolute;
  width: 100%;
  //z-index: -1;
  &::before{
    position: absolute;
    content: '';
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
  }
  video{
    width: auto;
	height: 500px;
	@media (min-width: $menu_tooggle_screeen_size) {
      width: 100%;
	  height: unset;
	}
  }
}

//advantage
#advantage + .flex-table{
  margin-bottom: 0;
}

.advantage_bg{
  
  justify-content: center;
  align-items: center;
  background-image: url($advantage_bg_url);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  counter-reset: css-counter 0;
  gap: 0;
  @media (min-width: $screen_sm) {
    gap: 1em;
	height: 450px;
  }
  .item{
    .pro_list{
	  margin: auto;
	  width: fit-content;
	}
    h3{
	  font-size: 1em;
	  font-weight: bold;
	  text-align: center;
	  border-radius: 100%;
	  padding: 1em;
	  background: #000;
	  background: #dab169;
	  color: #000;
	  counter-increment: css-counter 1;
	  @media (min-width: $screen_sm) {
        font-size: 1.2em;
		padding: 1.2em;
      }
	  &::before{
        content: counter(css-counter) ". ";
	  }
	  box-shadow: $box_shadow;
	}
  }
}
/*

  -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
	@-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
    }
    to {
      box-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }
  }
	*/
 

@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 1px rgba(255,255,255,0), 0 0 3px rgba(255,255,186,0.5), 0 0 5px rgba(243,213,50,0.5), 0 0 7px rgba(217,122,9,0.5), 0 0 9px rgba(145,78,2,0.5), 0 0 11px rgba(228,213,25,0.5), 0 0 13px rgba(248,231,121,0);
  }
  to {
    text-shadow: 0 0 2px rgba(255,255,255,0), 0 0 4px rgba(255,255,186,0.5), 0 0 6px rgba(243,213,50,0.5), 0 0 8px rgba(217,122,9,0.5), 0 0 10px rgba(145,78,2,0.5), 0 0 12px rgba(228,213,25,0.5), 0 0 14px rgba(248,231,121,0);
  }
}

@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}

@keyframes animate {
	from{
	    border-image: linear-gradient(-30deg, rgba(255,236,141,1) 0%, rgba(114,60,1,1) 20%, rgba(114,60,1,1) 50%, rgba(114,60,1,1) 80%, rgba(255,236,141,1) 100%) 1;
	}
	to {
		 //border-image: linear-gradient(-30deg, rgba(114,60,1,1) 0%, rgba(255,236,141,1) 20%, rgba(114,60,1,1) 50%, rgba(255,236,141,1) 80%, rgba(255,236,141,1) 100%) 1;
		 border-image: linear-gradient(-30deg, rgba(114,60,1,1) 0%, rgba(114,60,1,1) 20%, rgba(114,60,1,1) 50%, rgba(255,236,141,1) 80%, rgba(255,236,141,1) 100%) 1;
	}
}
