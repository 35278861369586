
.tip_body{
  main{
    //background-color: transparent;
  }
  .content{
	  -webkit-box-reflect: unset !important;
	  &::before{
	    background: none !important;
	  }
	}
  .certificating_wrap{
    padding-top: 0;
  }
}


#tip_title{
  z-index: 1;
  margin-bottom: 2em;
  &::before{
    content: '';
	display: block;
	width: 100%;	
	margin-bottom: 2em;
	
  }
  .ul{
    color: white;
  }
}
