
/* 圖片路徑 */
$img_path: 'img';




/* 色票 */
$colorMain1: #dab169; //#deb164; //#D2AD6D; //主題色(金)
$colorMain2: #000;  //副配色(黑)
$colorMain3: #FFF;  //副配色(白)

$colorSub1: #999; //內文/說明文字
$colorSub2: #BCBCBC; //說明/次要文字
$colorSub3: rgba(0, 0, 0, 0.8); //footer背景色

$colorAlert1: #F06; //強調色/注意(紅)
$colorAlert2: #0C6; //強調色/成功(綠)
$colorAlert3: #FFBA01; //強調色/安全(黃)

/* $colorOpacity1: rgba(0, 0, 0, 0.8); */
/* $colorGradient1: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.9) 100%); */


/* overlay */
$overlay_bg_color: $colorSub3;

/* overlayiFrame */
$overlayiFrame_width_xs: 75vw; //80vw;
$overlayiFrame_width_sm: 55vw; //60vw;
$overlayiFrame_height: 84vh; //85vh; //83vh; //85vh; //90vh; //80vh;
$overlayiFrame_min_width: 20em; //20220608, 阿榮


/* 分隔符號 -----------------------------------------------------------------------------------------------------*/ 
$divider: '/';

/* 箭頭 */
$arrow_color: $colorMain1;

/* 未發行 */
//$unpublished: $colorSub1;

/* SCROLLBAR 滾軸 */
$scrollbar_width: 0.6em; //0.375em;
$scrollbar_height: 0.6em; //0.375em;
$scrollbar_track_color: $colorSub3;
$scrollbar_thumb_color: $colorMain1;

/* back to top */
$scrolltop_color: $colorMain1;
$scrolltop_bg_color: $colorSub3;
$scrolltop_zindex: 990;//999;

/* servicing_wrap */
$servicing_zindex: 999;

/* ul li */
$ul_li_color: $colorMain1;

/* 基本設定 -----------------------------------------------------------------------------------------------------*/

//font 字
$font_size: 1em; //(16px)
$font_title: 1.2em;
$letter_spacing: 0.04em;
$h1_size: 2.5em;
$h2_size: 2em;
$h3_size: 1.5em;
$h4_size: 1em;
$text_shadow: 0 0.25em 0.62em $colorSub3;
$box_shadow: $text_shadow;


/*
$text_shadow_x: 1px;
$text_shadow_y: 1px;
$text_shadow_color: $colorSub1;
*/

//字距
//$letter_spacing: 0.1em;

//區塊行距
$banner_margin_bottom: 4em; //2em;
$section_margin_bottom:  7.2em; //4.5em;
$table_margin_bottom:  3em;

//a 連結
$a_link_color: $colorMain1;
$a_active_color: $colorMain1;
$a_hover_color: $colorMain1;
$a_visited_color: $colorMain1;


//間距
$margin_ls: 32px;
$margin_ss: 16px;
$margin_xs: 4px;
$margin_xx: 4px;
$padding_ls: 32px;
$padding_ss: 16px;
$padding_xs: 6px;
$padding_xx: 4px;

/*
$space_ls: 32px; //2em
$space_ss: 16px; //1em
$space_xs: 8px; //0.5em
$space_xx: 4px; //0.25em
*/

$space_ls: 2em;
$space_ss: 1em;
$space_xs: 0.5em;
$space_xx: 0.25em;

//高寬
$full_width: 100%;
$full_height: 100%;

//邊框
$border_width: 1px;
$border_style: solid;
$border_color: $colorSub2;
$border_radius: 6px;
$border_radius_round: 2em;
$border_radius_half: 25px;
$border_radius_circle: $full_width;

//selection 頁面選取文字
$selection_bg_color: $colorMain1;
$selection_color: $colorMain2;


/* 螢幕尺寸-------------------------------------------------------------------------------------------------------*/
//$screen_pc: 1920px;
$screen_lg: 75em; //1200px;
$screen_pad: 1024px;
$screen_md: 62em; //992px;
$screen_sm2: 56.25em; //900px;
$screen_sm: 50em; //800px; //768px;
$screen_xs: 32.25em; //500px;
$screen_xxs: 20em; //320px;


//QUOTE
$quote_color: $colorMain1;
$quote_before_url: '../'+ $img_path + '/left-quote-svgrepo-com-01.svg';



//安鈕
$btn_font_size: $h3_size;
$btn_letter_spacing: 0.04em;
$btn_fill_font_color: $colorMain2;
$btn_fill_bg_color: $colorMain1;
$btn_radius: 1em;
$btn_padding: 0.2em 1em;//0.1em 1em;

//按钮2
$btn_fill2_font_color: $colorMain3;
$btn_fill2_bg_color: $colorMain2;




//下拉選單
$select_option_bg_color: $colorMain1;


//語言選擇
$lang_color: $colorMain1;
$lang_checked_color: $colorMain3;
$lang_border_color: $lang_color;
$lang_border: 1px solid $lang_border_color;



/* 警戒 */
$alert_font_color: $colorAlert1;
$alert_font_size: 1.5em;

/* .disabled 灰階不可用 ------------------------------------------------------------------------------------------*/
$disabled_color: $colorSub3;

/* 蓋PPB框右上角 -------------------------------------- */
$coverX_bg_url: '../'+ $img_path + '/logo.png';



/* black_theme */
$black_theme_body_bg_color: $colorMain2;
$black_theme_body_font_color: $colorMain3;


/* body */
$body_font_size: $font_size;
$body_bg_color: $colorMain2;
//$body_bg_url: '../'+ $img_path + '/banner_bg2.jpg';



/* nav */
$logo_black_url: '../'+ $img_path + '/logo_black.png';
$logo_white_url: '../'+ $img_path + '/logo_white.png';
$nav_padding: 0.5em;
$nav_font_color: $colorMain1;

$menu_bar_color: $colorMain1;
$menu_close_color: $menu_bar_color;
$menu_bg_color: rgba(0,0,0,0.85); //$colorMain2;
$menu_tooggle_screeen_size: $screen_sm2;


/* main */
$main_bg_color: $colorMain3;

/* banner */
$banner_title_font_color: $colorMain1;
$banner_title_font_size: $h1_size;
$banner_title_line_height: $h2_size;
$banner_subtitle_font_size: $h3_size;
$banner_subtitle_line_height: $h2_size;
$banner_title_border: 0.06em solid $banner_title_font_color;
$banner_bg_url: '../'+ $img_path + '/banner_bg.jpg';

/* banner2 */
$banner2_bg_url: '../'+ $img_path + '/banner2_bg.jpg';
$banner2_title_font_size: $h3_size;
$banner2_title_font_color: $colorMain3;
$banner2_border: 0.05em solid $colorMain1;
$banner2_border_radius: 0.1em;

/* banner3 */
$banner3_btn_font_size: 1.4em;
$banner3_btn_font_size_sm2: 1.3em; //1.4em;


/* service 服務項目 */ 
$banner_service_bg_url: '../'+ $img_path + '/service_banner.png';
$service_item_01_bg_url: '../'+ $img_path + '/service_p_01.png';

$service_item_01_b_bg_url: '../'+ $img_path + '/service_f_01_b.png';
$service_item_01_a_bg_url: '../'+ $img_path + '/service_f_01_a.png';

$service_item_02_a_bg_url: '../'+ $img_path + '/service_f_02_a.png';

$service_item_03_b_bg_url: '../'+ $img_path + '/service_f_03_b.png';
$service_item_03_a_bg_url: '../'+ $img_path + '/service_f_03_a.png';

$service_item_04_a_bg_url: '../'+ $img_path + '/service_f_04_a.png';


$service_bg_02_url: '../'+ $img_path + '/service_bg_02.png';
$service_bg_03_url: '../'+ $img_path + '/service_bg_03.png';
$service_bg_04_url: '../'+ $img_path + '/service_bg_04.png';
$service_bg_06_url: '../'+ $img_path + '/service_bg_06.png';


//color: #2b2316;


/* section */
$section_title_font_size: 1.4em;
$section_title_font_color: $colorMain1;
$section_title_line_height: 2em; //1.8em;
$section_border: 0.02em solid $section_title_font_color;
$section_subtitle_font_size: 1.1em;
$section_subtitle_font_color: $colorMain1;


/* slide */
$slide_title_color: $colorMain1;
$slide_a_color: $colorMain3;
$slide_a_visited_color: $colorMain3;
$slide_h3_a_color: $slide_title_color;

/* footer */
$footer_bg_color: $colorSub3;
$footer_bg_url: '../'+ $img_path + '/footer_bg.jpg';

/* desc */
$desc_font_color: $colorSub1;
$desc_font_size: 0.8em;

/* table */
$table_thead_border: 0.02em solid $colorSub2;
$table_thead_height: 2.5em;
$table_tbody_tr_height: 4.5em;
$table_bg_color: $colorMain3;

/* table_pagination */
$table_pagination_font_color: $colorMain2;
$table_pagination_border: 1px solid $colorMain1;
$table_pagination_arror_color: $colorSub2;
$table_pagination_arror_hover_color: $colorMain2;
$table_pagination_arror_border: 1px solid $table_pagination_arror_color;
$table_pagination_active_color: $colorMain3;
$table_pagination_active_bg_color: $colorMain1;

/* search */
$search_border: 0.02em solid $colorMain1;
$search_close_font_color: $colorMain3;
$search_close_bg_color: $colorMain2;
$search_btn_color: $colorMain3;
$search_btn_bg_color: $colorMain2;
$search_bg_color: $colorMain3;
$search_text_color: $colorMain2;

/* teaching side-tag */
$teaching_tag_color: $colorMain2;
$teaching_tag_bg_color: $colorMain1;
$teaching_tag_before_url: '../'+ $img_path + '/teaching.svg';


/* teaching */
$teaching_bg_url: '../'+ $img_path + '/teaching_bg.png';
$logo_binance_url: '../'+ $img_path + '/logo_binance.svg';
$logo_max_url: '../'+ $img_path + '/logo_max.svg';
$logo_metamask_url: '../'+ $img_path + '/logo_metamask.svg';
$logo_metatalk_url: '../'+ $img_path + '/logo_metatalk.svg';


/* purchasing */
$purchasing_bg_url: '../'+ $img_path + '/purchasing.svg';
$eth_price_bg_url: $teaching_tag_before_url;
$purchasing_color: $colorMain3;
$purchasing_footer_bg_color: $colorMain3;

/* certificating */
$certificating_bg_url: '../'+ $img_path + '/certificating_bg.png';
$certificating_bg_color: #050d16;
$certificating_title_bg_url: '../'+ $img_path + '/certificating_title_decoration.svg';
$certificating_stamp_bg_url: '../'+ $img_path + '/certificating_stamp.png';

/* cooperation */
$logo_shangsun_bg_url: '../'+ $img_path + '/logo_shangsun_bg.jpg';
$logo_netbridge_bg_url: '../'+ $img_path + '/logo_netbridge_bg.jpg';
$logo_bingx_bg_url: '../'+ $img_path + '/logo_bingx_bg.jpg';

/* advantage */
$advantage_bg_url: '../'+ $img_path + '/advantage_bg.png';