//頁面左右PADDING
@mixin padding{
  padding: 0 $padding_xs !important;
  @include breakpoint(min-width $screen_pad) {
    padding: 0 $padding_ss !important;
  }
}

@mixin margin{
  margin: 0 $padding_xs !important;
  @include breakpoint(min-width $screen_pad) {
    margin: 0 $padding_ss !important;
  }
}

@mixin column-margin-left {
  margin-left: $margin_xs; //欄與欄間的間距
  @include breakpoint(min-width $screen_mobile2){
    margin-left: $margin_ss; //欄與欄間的間距
  }
  @include breakpoint(min-width $screen_mobile){
    margin-left: $margin_ls; //欄與欄間的間距
  }
}

//carousel, right padding
@mixin nav-next-right {
  padding-right: $padding_xs !important; //欄與欄間的間距
  @include breakpoint(min-width $screen_mobile2){
    padding-right: $padding_ss !important; //欄與欄間的間距
  }
  @include breakpoint(min-width $screen_mobile){
    padding-right: $padding_ls !important; //欄與欄間的間距
  }
}

@function line-height($font-size){
  //字距為字型大小的1.5倍
  //@return ($font-size * 1.5);
  //字距為字型大小的1.8倍
  @return ($font-size * 1.6);
}

//彩色ICON圖
@mixin color-icon($color-icon-img,$top:0,$left:-2px){ //$top:-4px
  //align-self: flex-start !important; //若地址超過2行, ICON置上
  &::before {
    position: relative;
    top: $top;  //2px
    //left: $left; //2px;
    content: '';
    background-image: url($color-icon-img);
    width: 24px; //$mhicon_bg_width_mobile;
    height: 24px; //$mhicon_bg_height_mobile;
    display: block; //inline-block;
	//display: inline-block;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
    @include breakpoint(min-width $screen-mobile2) {
      width: $mhicon_bg_width_pad;
      height: $mhicon_bg_height_pad;
      //top: $top2;
    }
  }
}

//背景圖
@mixin background-image($url){
  background: url($url) 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
}
@mixin backgound-size($backgound-size){
  -webkit-backgound-size: $backgound-size;
  -o-backgound-size: $backgound-size;
  -moz-backgound-size: $backgound-size;
  background-size: $backgound-size;
}
/*
@mixin main-linear-gradient{
  background: linear-gradient($main_deg , $main_first_color $main_first_stop, $main_second_color $main_second_stop, $main_third_color $main_third_stop);
}

@mixin main-linear-gradient-negtive{
  background: linear-gradient( - $main_deg , $main_first_color $main_first_stop, $main_second_color $main_second_stop, $main_third_color $main_third_stop);
}

@mixin secondary-linear-gradient{
  background: linear-gradient( $secondary_deg , $secondary_first_color $secondary_first_stop, $secondary_second_color $secondary_second_stop, $secondary_third_color $secondary_third_stop);
}

@mixin secondary-linear-gradient-negtive{
  background: linear-gradient( - $secondary_deg , $secondary_first_color $secondary_first_stop, $secondary_second_color $secondary_second_stop, $secondary_third_color $secondary_third_stop);
}
*/
@mixin word-break{
  word-break: break-all; //for firefox
  word-break: break-word; //chrome
}
@mixin width-max-content{
  width: -moz-max-content; 
  width: -webkit-max-content; 
  width: max-content;
}
@mixin max-width-max-content{
  max-width: -moz-max-content; 
  max-width: -webkit-max-content; 
  max-width: max-content;
}
@mixin min-width-max-content{
  min-width: -moz-max-content; 
  min-width: -webkit-max-content; 
  min-width: max-content;
}
@mixin ellipsis{
  overflow: hidden;
  //white-space: nowrap;
  -o-text-overflow: ellipsis;   /* required for Opera */
  -ms-text-overflow: ellipsis;  /* required for IE8, allegedly */
  text-overflow: ellipsis;
}
@mixin ellipsis-oneline{
  overflow: hidden;
  white-space: nowrap;  /* 讓firefox在一行時有... */
  -o-text-overflow: ellipsis;   /* required for Opera */
  -ms-text-overflow: ellipsis;  /* required for IE8, allegedly */
  text-overflow: ellipsis;
}
@mixin line-clamp($box-orient:vertical,$line-clamp:3){
  display: -webkit-box; 
  -webkit-box-orient: $box-orient;
  -webkit-line-clamp: $line-clamp;
  word-break: break-all; //for firefox;
  word-break: break-word;
  //white-space: pre-wrap;
}
@mixin absolute-middle{
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin:auto;
}
@mixin opacity($opacity:0.7){
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=$opacity*100);
  filter: alpha(opacity=$opacity*100);
  opacity: $opacity;
}
@mixin rotate($rotate){
  -ms-transform: rotate($rotate);
  -webkit-transform: rotate($rotate);
  transform: rotate($rotate);
}
@mixin scaleY($scaleY){
  -moz-transform: scaleY($scaleY);
  -o-transform: scaleY($scaleY);
  -webkit-transform: scaleY($scaleY);
  transform: scaleY($scaleY);
}
@mixin box-shadow($box-shadow){
  -o-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  -webkit-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}


@mixin border-box($border-box){
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin inline-flex{
  display: -webkit-inline-box;      
  display: -moz-inline-box;        
  display: -ms-inline-flexbox;     
  display: -webkit-inline-flex;  
  display: inline-flex;
}

@mixin flex-direction($flex-direction:row){
  -webkit-flex-direction: $flex-direction;
  -moz-flex-direction: $flex-direction;
  -ms-flex-direction: $flex-direction;
  flex-direction: $flex-direction;
}

@mixin display-inline-flex($flex-align:center,$flex-direction:row){
  display: -webkit-inline-box;      
  display: -moz-inline-box;        
  display: -ms-inline-flexbox;     
  display: -webkit-inline-flex;  
  display: inline-flex; 
  -webkit-box-align: $flex-align;
  -moz-box-align: $flex-align;
  -ms-flex-align: $flex-align;
  -webkit-align-items: $flex-align;
  align-items: $flex-align;
  -webkit-flex-direction: $flex-direction;
  -moz-flex-direction: $flex-direction;
  -ms-flex-direction: $flex-direction;
  flex-direction: $flex-direction;
}

@mixin display-flex($flex-align:center,$flex-justify:center,$flex-direction:row){
  display: -webkit-box;      
  display: -moz-box;        
  display: -ms-flexbox;     
  display: -webkit-flex;  
  display: flex; 
  -webkit-box-align: $flex-align;
  -moz-box-align: $flex-align;
  -ms-flex-align: $flex-align;
  -webkit-align-items: $flex-align;
  align-items: $flex-align;
  -webkit-flex-direction: $flex-direction;
  -moz-flex-direction: $flex-direction;
  -ms-flex-direction: $flex-direction;
  flex-direction: $flex-direction;
  justify-content: $flex-justify;
}

@mixin flex-wrap($flex-wrap:wrap){
  -webkit-flex-wrap: $flex-wrap;
  -moz-flex-wrap: $flex-wrap;
  -ms-flex-wrap: $flex-wrap;
  flex-wrap: $flex-wrap;
}

@mixin flex-flow($flex-flow){
  -webkit-flex-flow: $flex-flow;
  -moz-flex-flow: $flex-flow;
  -ms-flex-flow: $flex-flow;
  flex-flow: $flex-flow;
}

@mixin flex($flex-value){
  -webkit-flex: $flex-value;
  -moz-flex: $flex-value;
  -ms-flex: $flex-value;
  flex: $flex-value;
}

@mixin order($flex-order){
  -webkit-box-ordinal-group: $flex-order;
  -moz-box-ordinal-group: $flex-order;
  -ms-flex-order: $flex-order; 
  -webkit-order: $flex-order; 
  order: $flex-order;
}


@mixin user-select($select-action:none){
  -webkit-user-select: $select-action;  /* Chrome all / Safari all */
  -moz-user-select: $select-action;     /* Firefox all */
  -ms-user-select: $select-action;      /* IE 10+ */
  user-select: $select-action;          /* Likely future */

  //-webkit-touch-callout:none;-khtml-user-select:none;

}
@mixin width-max-content{
  width: -moz-max-content; 
  width: -webkit-max-content; 
  width: max-content;
}
@mixin max-width-max-content{
  max-width: -moz-max-content; 
  max-width: -webkit-max-content; 
  max-width: max-content;
}
@mixin min-width-max-content{
  min-width: -moz-max-content; 
  min-width: -webkit-max-content; 
  min-width: max-content;
}
@mixin absolute-middle{
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin:auto;
}
@mixin opacity($opacity:0.7){
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=$opacity*100);
  filter: alpha(opacity=$opacity*100);
  opacity: $opacity;
}
@mixin rotate($rotate){
  -webkit-transform: rotate($rotate);
  -moz-transform: rotate($rotate);
  -o-transform: rotate($rotate);
  -ms-transform: rotate($rotate);
  transform: rotate($rotate);
}
@mixin scaleY($scaleY){
  -moz-transform: scaleY($scaleY);
  -o-transform: scaleY($scaleY);
  -webkit-transform: scaleY($scaleY);
  transform: scaleY($scaleY);
}
@mixin scale($scaleX,$scaleY){
  -moz-transform: scale($scaleX,$scaleY);
  -o-transform: scale($scaleX,$scaleY);
  -webkit-transform: scale($scaleX,$scaleY);
  transform: scale($scaleX,$scaleY);
}

@mixin box-sizing($box-sizing){
  -webkit-box-sizing: $box-sizing;
  -moz-box-sizing: $box-sizing;
  box-sizing: $box-sizing;
}



@mixin rwd-flex(){
  @include display-flex(center, center, column);
  @media (min-width: $screen_sm) {
	  @include display-flex(center, center, row);
      justify-content: space-between;
	}
  
  
  
}


@mixin flex-wrap($flex-wrap){
  -webkit-flex-wrap: $flex-wrap;
  -moz-flex-wrap: $flex-wrap;
  -ms-flex-wrap: $flex-wrap;
  flex-wrap: $flex-wrap;
}

@mixin flex-flow($flex-flow){
  -webkit-flex-flow: $flex-flow;
  -moz-flex-flow: $flex-flow;
  -ms-flex-flow: $flex-flow;
  flex-flow: $flex-flow;
}

@mixin flex($flex-value){
  -webkit-flex: $flex-value;
  -moz-flex: $flex-value;
  -ms-flex: $flex-value;
  flex: $flex-value;
}

@mixin display-flex-align-items($align-items:center){
  -webkit-box-align: $align-items;
  -moz-box-align: $align-items;
  -ms-flex-align: $align-items;
  -webkit-align-items: $align-items;
  align-items: $align-items;
}


@mixin order($flex-order){
  -webkit-box-ordinal-group: $flex-order;
  -moz-box-ordinal-group: $flex-order;
  -ms-flex-order: $flex-order; 
  -webkit-order: $flex-order; 
  order: $flex-order;
}


@mixin user-select($select-action:none){
  -webkit-user-select: $select-action;  /* Chrome all / Safari all */
  -moz-user-select: $select-action;     /* Firefox all */
  -ms-user-select: $select-action;      /* IE 10+ */
  user-select: $select-action;          /* Likely future */
}

@mixin after-border-bottom($border-width:$mh_border_width, $border-style:$mh_border_style, $border-color:$mh_border_color){
  content: '';
  width: $full_width;
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: $border-width $border-style $border-color;
}


@mixin setScrolltopColors($bgColor: #333, $arrowColor: white, $bgColorHover: $bgColor, $arrowColorHover: $arrowColor) {
  #scrolltop-bg {
    fill: $bgColor;
  }
  #scrolltop-arrow {
    fill: $arrowColor;
  }
  /*
  a:hover {
    #scrolltop-bg {
      fill: $bgColorHover;
    }
    #scrolltop-arrow {
      fill: $arrowColorHover;
    }
  }
  */
}