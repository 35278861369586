.purchasing_modal{

  .content{
    position: relative;
	
    /* fix */
    //-webkit-transform: translateZ(0);
    
	padding-bottom: 0 !important;
	
	display: block; /* IOS STICKY */
	@media (min-width: $screen_xs) {
	  /* position: sticky */
	  padding-bottom: 1em !important; 
	  
	  @include display-flex(center,space-around, column);
	  @include display-flex(center,space-between, column);
	  gap: 1em;
	}
    .certificating_body{
	  height: unset; //70%;
	  @include display-flex(center,space-around, column);
	  @media (min-width: $screen_xs) {
	    height: 70%;
	    @include display-flex(center,space-around, row);
	  }
	  &>div{
	    width: 100%; //width: 68%;
		@media (min-width: $screen_xs) {
		  width: 50%;
		}
	  }
	  .certificating_img{
	    box-shadow: none;
	  }
	  .certificating_img_info{
	    padding-bottom: 2em; //for ios
	    font-size: 1em;
	    line-height: unset;
		@media (min-width: $screen_xs) {
		  //line-height: 1.4em;
		}
		& > div{
	      & > span{
	  	    &:first-child{
	  	      width: 35%;
	  	    }
	      }
	    }
		.artwork_info{
		  color: $colorMain1;
		  text-decoration: underline;
		}
	  }
	}
  }

}

#purchasing_title{
  text-align: center;
}



.purchasing_footer{
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  
  //align-self: flex-start; /* <-- this is the fix */
  
  //position: fixed;
  bottom: 0;
  width: 100%;
  /*
  width: $overlayiFrame_width_xs;
  @media (min-width: $menu_tooggle_screeen_size) {
	width: $overlayiFrame_width_sm;
  }
  */
  background: $purchasing_footer_bg_color;
  padding: 1em 0.5em;
  margin-top: 1em;
  text-align: center;
  @media (min-width: $screen_xs) {
    //position: relative;
  }
  &::after{
    content: '';
	display: block;	
  }
  
  button{
      &:not(:last-child){
	    margin-right: 1em;	  
	    @media (min-width: $screen_xs) {
	      margin-right: 1.618em;
	    }
	  }
      padding: 0.5em 0.9em;
	  @media (min-width: $screen_xs) {
	      padding: 0.5em 2em;
	  }
      //background: #dab169;
	  &, a{
	    color: #fff;
	    text-decoration: none;
	  }
  }
}
