/* style.scss */

//font
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,400,500,600,700&display=swap&subset=chinese-traditional);

*{
  @include box-sizing(border-box);
  &:focus{
    outline: none;
  }
  border: 0;
  //margin:0; padding:0; border:0; outline:0;
}


html{
  /*
  //scrollbar
  scrollbar-width: $scrollbar_width; //firefox
  scrollbar-height: $scrollbar_height; //firefox
  
  //chrome
  ::-webkit-scrollbar{
	//width: $chrome_scrollbar_width; 
	width: $scrollbar_width;
	height: $scrollbar_height;
	
  }
  ::-webkit-scrollbar-track{
    background: $scrollbar_track_color;
	
  }
  ::-webkit-scrollbar-thumb{
    background: $scrollbar_thumb_color;
	border-radius: 0.1em;
  }
  */
  scroll-behavior: smooth;
}


//連結
a{
  color: $a_link_color;
  &:visited{
    color: $a_visited_color;
  }
  
}

//警戒
.alert{
  color: $alert_font_color;
}

//箭頭
.arrow{
  color: $arrow_color;
}
.instruction{
  line-height: 2em;
  text-align: right;
}

p span:not(:last-child), li span:not(:last-child){
    margin-right: 0.6em;
}

//圖片
img{
  vertical-align: middle;
}

header, main{
  padding: $space_xs;
}


body{
  position: relative;
  font-size: $body_font_size;
  line-height: line-height($body_font_size);
  letter-spacing: 0.04em;
  //text-align: justify;
  text-align: initial;
  text-underline-offset: 0.2em;
  text-decoration-thickness: 0.01em;
  background-color: $body_bg_color;
  
  /*
  background-image: url($body_bg_url);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  
  background: rgba(0,0,0,0.75) url($body_bg_url);
  */
}

.nav{
  
  padding: $nav_padding*3 $nav_padding;
  color: $nav_font_color;
  li{
    display: inline;
	&:not(:last-child){
	  margin-right: 0.5em; //1em;
	}
  }
  background-color: $footer_bg_color;
}

/*

*/

footer{
  //height: 6em;
  @include display-flex(center,flex-start, column);
  align-items: flex-start;
  text-align: left;
  @media (min-width: $screen_xs) {
    @include display-flex(center,center,row);
    justify-content: space-between; 
    text-align: right;
  }
}

header, footer, main > *{
  margin: auto;
  width: 100%;
  @media (min-width: $screen_sm) {
    width: 85%; //80%;
  }
  @media (min-width: $screen_lg) {
    width: 65%; //60%;
  }  
}

header{
  @include display-flex(center,center,row);
  justify-content: space-between; 
  .logo{
	  img{
	    //display: none;
	    //width: 120px;
		//width: 8.75em;
		width: 9em;
	  }
	  /*
	  //width: 120px;
	  //height: 25px;
	  width: 140px;
	  height: 30px;
	  //background-image: url($logo_black_url);
	  background-image: url($logo_white_url);
	  background-position: center left;
	  background-repeat: no-repeat;
	  //background-size: 120px 25px;
	  background-size: 140px 30px;
	  */
  }
  nav{
    position: relative;
	z-index: 900;
    @include display-flex(center,flex-start, row);
    flex-direction: row-reverse;
    & > * {margin: 0.5em}
	text-align: left;
    //@media (min-width: $screen_sm) {
	@media (min-width: $menu_tooggle_screeen_size) {
      flex-direction: row;
	  text-align: right;
    }
  }
  
  .search{
    display: block;
	margin: 0;
	a{
	  text-decoration: none;
	}
	.box{
	  
	  border-radius: 2em;
	  //padding: 0.1em 0.6em 0.3em 0.8em;
	  //padding: 0.1em 0.6em;
	  padding: 0.05em 0.5em;
	  margin-bottom: 0;
	  .magnifier{
	    
		-webkit-transform: scaleX(-1);
        transform: scaleX(-1);
	  }
	}
	.text{
	  margin-right: 0.24em;
	  color: $search_text_color;
	}
    @media (min-width: $menu_tooggle_screeen_size) {
	  display: none;
    }
  }
}

.lang_list{
 margin-right: 0.6em !important;
 display: inline-flex;
 width: fit-content;
 .icon_img{
   width: 20px;
   height: auto;
   margin-right: 0.2em;
 }
 label:not(:last-child){
   &::after{
     content: '|';
	 color: $lang_color;
	 margin-left: 0.2em;
	 margin-right: 0.2em;
   }
   
 }
 input[type="radio"]:checked+label { color: $lang_checked_color; }
 border: $lang_border;
 border-radius: 1.5em;
 padding: 0.2em 0.4em;
}

.banner{
  position: relative;
  margin-bottom: $banner_margin_bottom;
  @include display-flex(center, center,column);
  align-items: center;
  justify-content: center;
  height: 460px;
  background-image: none; //url($banner_bg_url);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  @media (min-width: $screen_sm) {
    @include display-flex(center, center,row);
	height: 500px;
  }
  img, .title{
    z-index: 1;
  }
  img{
    margin-right: 0;
    width: 7.5em; //120px;
    filter: drop-shadow($text_shadow);
	@media (min-width: $screen_sm) {
      width: 9.8em; //10em; //170px;
	  margin-right: 1em;
    }
  }
  .title{    
    h1{
	  font-weight: 500;
	  color: $banner_title_font_color;
	  //border-bottom: $banner_title_border;
	  font-size: $banner_title_font_size*(3/4);
	  line-height: $banner_title_line_height;
	  text-decoration: underline;
      text-decoration-thickness: 0.08em;
      text-underline-offset: 0.5em;
	  @media (min-width: $screen_sm) {
        font-size: $banner_title_font_size;
      }
	}
	h3{
	  font-weight: 500;
	  font-size: $banner_subtitle_font_size*(3/4);
	  //line-height: $banner_subtitle_line_height;
	  letter-spacing: 0.05em;
	  @media (min-width: $screen_sm) {
        font-size: $banner_subtitle_font_size;
		letter-spacing: 0.03em;
      }
	}
	text-align: center;
	text-shadow: $text_shadow;
  }
  .btn_fill{
    //position: relative;
    cursor: pointer;
    margin-top: 1em;
	font-size: $banner3_btn_font_size_sm2;
	@media (min-width: $menu_tooggle_screeen_size) {
      font-size: $banner3_btn_font_size;
    }
  }
}

.banner2{
  margin-bottom: 0;
  position: relative;
  &::before{
    pointer-events: none;
	content: ' ';
    position: absolute;
	//z-index: 1;
	top: 10%;
	left:6%;
	width: 88%;
	height: 76%;
	border: $banner2_border;
	border-radius: 0.5em;
  }
  //display: block;
  height:300px;
  background-image: url($banner2_bg_url);
  background-position: center center;
  @media (min-width: $screen_sm) {
	height:350px;
  }
  img{
    width: 12.5em; //200px;
	@media (min-width: $screen_sm) {
	  width: 18.75em; //300px;
	}
  }
  .title{ 
    position: relative;
    //top: 50%;
    //transform: translateY(-50%);  
	
	width: 88%;
	
    h3{
	  color: $banner2_title_font_color;
	  font-size: $banner2_title_font_size;
	  border-bottom: 0;
	  letter-spacing: 0.4em;
	}
  }
}

.banner2, .banner3{
  padding: 1em;
}

.banner3{  
  height: 200px;
  margin-bottom: 0;
  background-image: url($banner2_bg_url);
  background-position: center center;
  @media (min-width: $screen_sm) {
	 height: 150px;
	}
  .title{
    h3{
      display: inline;
      color: $banner2_title_font_color;
      font-size: $banner2_title_font_size;
      border-bottom: 0;
      letter-spacing: 0.4em;
    }
	
  }
}

.banner_service{
  height: 50px;
  filter: blur(50px);
  opacity: 1;
  padding: 1em;
  background-image: url($banner_service_bg_url);
  justify-content: flex-end !important;
  align-items: flex-end !important;
  
  @media (min-width: $screen_sm) {
    height: 50px;
    opacity: 0.75;
  }
  .title{
    text-shadow: 0.1em 0.1em 0.2em rgba(0,0,0,0.85);
  }
}

main{
  background-color: $main_bg_color;
}

section{
  h2{
    color: $section_title_font_color;
    font-size: $section_title_font_size*(4/5);
	letter-spacing: 0.05em;
	line-height: $section_title_line_height;
    //border-bottom: $section_border;
	//margin-bottom: 0.4em;
	text-decoration: underline;
	text-underline-offset: 0.25em;
	@media (min-width: $screen_sm) {
	  font-size: $section_title_font_size;
	}
  }
  h3{
    font-size: $section_subtitle_font_size;
	letter-spacing: 0.05em;
	color: $section_title_font_color;
	line-height: $section_title_line_height;
  }
  p{
    a{
	  margin-right: 1em;  
	}
  }
}

section:not(#art):not(#ip):not(#assets):not(#team):not(#enpower):not(#cooperation):not(#purchasing_title):not(#advantage), .slide, .flex-table{
  margin-bottom: $section_margin_bottom;
}

section#art, section#ip{
  @include display-flex(center, space-between, row);
}


footer{
  //text-align: center;
  //background-color: $footer_bg_color;
  background-image: url($footer_bg_url);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  .logo{
	  img{
		width: 9.6em; //8.75em;
	  }
  }
  .menu{
    margin-left: 2.4em;
  }
}

.black_theme{
  background-color: $black_theme_body_bg_color;
  color: $black_theme_body_font_color;
  /*
  header{
  @include display-flex(center, center, row);
  justify-content: space-between;
  .logo{
	  //background-image: url($logo_white_url);
    }
  }
  */
  main{
    background-color: unset;
  }
}

.desc{
  text-align: justify;
  color: $desc_font_color;
  font-size: $desc_font_size;
  .alert{
    font-size: $alert_font_size;
  }
}

.ul{
  list-style: none;
  padding-inline-start: 0; //useragent
  li{
    &:before {
      content: "\27A4";
      padding-right: 0.25em;
	  color: $ul_li_color;
	  font-size: 1em;
    }
  }
}

.admin_main{
  nav{
    
    @media (min-width: $menu_tooggle_screeen_size) {
      flex-direction: row-reverse;
    }
	  
  }
  main{
    min-height: 80vh;
  }
}

.head_img{
  width: 2em;
  //height: 2em;
  border-radius: 100%;
}


