.purchasing_art{
  & ~ main{ padding-bottom: 4em; }
  h1, h2, .close{
    font-size: 1.3em;
	letter-spacing: 0.1em;
	@media (min-width: $screen_sm){
	  font-size: 1.4em;
	  letter-spacing: 0.2em;
	}
  }
  h1, h3{
    font-size: 1.6em;
  }
  .lang_list{
    border: 0;
	font-size: 0.9em;
	input[type=radio]{
	  display: none;
	}
	@media (min-width: $screen_sm){
	  font-size: 1em;
	}
  }  
  label, .close{
    cursor: pointer;
  }
  span{
    &:not(:last-child){
	  margin-right: 0.5em;
	}
  }
  img, video, audio{
    width: 100%;
  }
  audio{
    @media (min-width: $screen_sm){
      width: 60%;
	}
  }
  button{
    padding: 0.8em;
	width: 100%;
    &:not(:last-child){
      margin-right: 0;
	}
  }
  .btn_sub{
    background: #dab169;
    color: #000;
  }
  .flex_table{
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1em;
	padding: 1em;
	&.column{
	  flex-direction: column;
	  justify-content: flex-start;
	}
	@media (min-width: $screen_sm){
      flex-direction: row;
      justify-content: space-between;
	  padding: 2em 1em;
    }
  }
  .btn_wrap{
    position: fixed;
	z-index: 999;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 0.5em;
	background: #fff;
    flex-direction: row !important;
    @media (min-width: $screen_sm){
      position: relative;
	  padding: 0;
	  flex-direction: column !important;
    }
  }
  .master{
    justify-content: flex-start;
  }
  .detail{
    .menu{
	  border-top: 1px solid #999;
	  & > li{
	    display: flex;
		flex-direction: column;
		gap: 0.4em;
		@media (min-width: $screen_sm){
		  flex-direction: row;
		}
		& > span{
		  width: 100%;
		  &:first-child{
		    color: #dab169;
			@media (min-width: $screen_sm){
			  width: 15%;
			}
		  }
		  &:last-child{
			@media (min-width: $screen_sm){
		      width: 85%;
			}
		  }
		}
	  }
	}
  }
  .certificate{
    font-style: italic;
	//color: #dab169;
  }
}
